import * as React from 'react';
import { MainHeading, SubPageLayout } from '../components';
import _ from 'lodash';
import BlogEntry from '../components/BlogEntry/BlogEntry';
import newsAndInfoData from '../data/newsAndInfo.json';
import parseISO from 'date-fns/parseISO';
import dateIsPast from 'date-fns/isPast';
import formatDate from 'date-fns/format';
import { Container } from 'reactstrap';

const getPostId = ({ postDate, title }: any) => {
  return `${title}_${postDate}`;
};

export const NewsAndInfoPageInternal = (newsAndInfo: typeof newsAndInfoData) => {
  const newsEntryModel = _.map(newsAndInfo.newsEntries, (item) => {
    const { postDate, archiveDate, title } = item;
    const parsedPostDate = _.isDate(postDate) ? postDate : parseISO(postDate);
    const parsedArchiveDate = archiveDate ? _.isDate(archiveDate) ? archiveDate : parseISO(archiveDate) : null;

    return {
      ...item,
      id: getPostId({ postDate, title }),
      parsedPostDate,
      parsedArchiveDate,
      isArchived: parsedArchiveDate && dateIsPast(parsedArchiveDate),
      formattedPostDate: formatDate(parsedPostDate, 'yyyy-MM-dd'),
    };
  });

  return (
    <SubPageLayout
      title="News and Info"
      imageSrc={newsAndInfo.headerImageSrc}
    >
      <MainHeading
        label="News and Info"
      />

      <Container>
        {_.isEmpty(newsEntryModel) && (
          <>
            <p className="text-center">Check back later to keep up with the latest RMECU news.</p>
          </>
        )}
        <>
          {
            _.map(newsEntryModel, (newsEntry, i) => {
              const isLast = i === _.size(newsEntryModel) - 1;

              return (
                <React.Fragment key={newsEntry.id}>
                  <BlogEntry {...newsEntry} isActive={!newsEntry.isArchived} />

                  {!isLast && <hr />}
                </React.Fragment>
              );
            })
          }
        </>
      </Container>
    </SubPageLayout>
  );
};

export default () => <NewsAndInfoPageInternal {...newsAndInfoData} />;
